export default [
	/*
	{
		img: require('../imgs/themes/.webp'),
		name: '',
		category: '',
		url: '?utm_campaign=grapeland-elementor&utm_source=website_travolgi&utm_medium=referral&aff=travolgi',
		new: true
	},
	*/
	{
		img: require('../imgs/themes/grapeland-ele.webp'),
		name: 'GrapeLand - Elementor Kit for WordPress eCommerce of Wineries and Vineyards, Fast and SEO-Friendly',
		category: 'elementor, wordpress, wine, ecommerce, distillery, restaurant',
		url: 'https://www.templatemonster.com/elementor-templates/grapeland-elementor-kit-for-wordpress-ecommerce-of-wineries-and-vineyards-fast-and-seo-friendly-512096.html?utm_campaign=grapeland-elementor&utm_source=website_travolgi&utm_medium=referral&aff=travolgi',
		new: true
	},
	{
		img: require('../imgs/themes/fury-ele.webp'),
		name: 'Fury - The Ultrafast Multipurpose Elementor Kit for High-Performance and Stylish Wordpress eCommerce',
		category: 'elementor, wordpress, multipurpose, ecommerce, business',
		url: 'https://www.templatemonster.com/elementor-templates/fury-the-ultrafast-multipurpose-elementor-kit-for-high-performance-and-stylish-wordpress-ecommerce-511119.html?utm_campaign=fury_elementor&utm_source=website_travolgi&utm_medium=referral&aff=travolgi',
		new: true
	},
	{
		img: require('../imgs/themes/aten-ele.webp'),
		name: 'Aten – The Ultimate MultiPurpose Elementor Kit to Build Your Responsive Website in Minutes',
		category: 'elementor, wordpress, multipurpose, business, creative',
		url: 'https://www.templatemonster.com/elementor-templates/aten-the-ultimate-multipurpose-elementor-kit-to-build-your-responsive-website-in-minutes-509292.html?utm_campaign=aten_elementor&utm_source=website_travolgi&utm_medium=referral&aff=travolgi',
		new: true
	},
	{
		img: require('../imgs/themes/magpress-ele.webp'),
		name: 'MagPress – Elementor Kit for Modern Blogs & Magazines Wordpress Website Template',
		category: 'elementor, wordpress, magazine, blog, content creator',
		url: 'https://www.templatemonster.com/elementor-templates/magpress-elementor-kit-for-modern-blogs-amp-magazines-wordpress-website-template-508579.html?utm_campaign=magpress_elementor&utm_source=website_travolgi&utm_medium=referral&aff=travolgi',
		new: true
	},
	{
		img: require('../imgs/themes/memory-html.webp'),
		name: 'Memory Master – The Ultimate HTML5 Memory Arcade Game',
		category: 'html5, games, arcade game, memory game',
		url: 'https://www.templatemonster.com/html5-games/memory-master-the-ultimate-html5-memory-arcade-game-508578.html?utm_campaign=arcade-game_html&utm_source=website_travolgi&utm_medium=referral&aff=travolgi',
		new: true
	},
	{
		img: require('../imgs/themes/space-invaders-html.webp'),
		name: 'Space Defenders – Responsive and Customizable Space Invaders-Style HTML5 Game',
		category: 'html5, games, arcade game, space invaders',
		url: 'https://www.templatemonster.com/html5-games/space-defenders-responsive-and-customizable-space-invaders-style-html5-game-504562.html?utm_campaign=arcade-game_html&utm_source=website_travolgi&utm_medium=referral&aff=travolgi',
		new: true
	},
	{
		img: require('../imgs/themes/runner-alien-html.webp'),
		name: 'Alien Runner – A Fast & Thrilling HTML5 Customizable Game',
		category: 'html5, games, arcade game, runner alien',
		url: 'https://www.templatemonster.com/html5-games/alien-runner-a-fast-amp-thrilling-html5-customizable-game-504561.html?utm_campaign=arcade-game_html&utm_source=website_travolgi&utm_medium=referral&aff=travolgi',
	},
	{
		img: require('../imgs/themes/whack-bug-html.webp'),
		name: 'Bug Smasher – The Fast-Paced "Whack a Bug" Arcade HTML5 Game',
		category: 'html5, games, arcade game, whack a bug, smash',
		url: 'https://www.templatemonster.com/html5-games/bug-smasher-the-fast-paced-whack-a-bug-arcade-html5-game-504560.html?utm_campaign=arcade-game_html&utm_source=website_travolgi&utm_medium=referral&aff=travolgi',
	},
	{
		img: require('../imgs/themes/tris-html.webp'),
		name: 'Tris Arcade Pro – Responsive, High-Performance & Customizable HTML5 Game',
		category: 'html5, games, arcade game, tris',
		url: 'https://www.templatemonster.com/html5-games/tris-arcade-pro-responsive-high-performance-amp-customizable-html5-game-502932.html?utm_campaign=arcade-game_html&utm_source=website_travolgi&utm_medium=referral&aff=travolgi',
		new: true
	},
	{
		img: require('../imgs/themes/bizboost-html.webp'),
		name: 'BizBoost - The Best Choice for Business Growth Website Ultimate Template',
		category: 'html5, tailwindcss, corporate, business growth, creative',
		url: 'https://www.templatemonster.com/website-templates/bizboost-the-best-choice-for-business-growth-website-ultimate-template-498268.html?utm_campaign=bizboost_html&utm_source=website_travolgi&utm_medium=referral&aff=travolgi',
	},
	{
		img: require('../imgs/themes/learnfit-ele.webp'),
		name: 'LearnFit - Elementor Kit Comprehensive Course & Learning Template Kit for WordPress',
		category: 'elementor, wordpress, learn, courses, yoga, pilates, gym',
		url: 'https://www.templatemonster.com/elementor-templates/learnfit-elementor-kit-comprehensive-course-amp-learning-template-kit-for-wordpress-473895.html?utm_campaign=learnfit_html&utm_source=website_travolgi&utm_medium=referral&aff=travolgi',
	},
	{
		img: require('../imgs/themes/kidcare-html.webp'),
		name: 'KidCare | Babysitter Landing Page Template with Tailwind CSS',
		category: 'landing page, html5, tailwindcss, babysitter, child, education',
		url: 'https://www.templatemonster.com/landing-page-templates/kidcare-babysitter-landing-page-template-with-tailwind-css-468766.html?utm_campaign=kidcare_html&utm_source=website_travolgi&utm_medium=referral&aff=travolgi',
	},
	{
		img: require('../imgs/themes/grapeland-html.webp'),
		name: 'GrapeLand - The Ultimate HTML5 Template for Wineries and Vineyards, Fast and SEO Optimized',
		category: 'html5, wine, ecommerce, distillery, restaurant',
		url: 'https://www.templatemonster.com/website-templates/grapeland-the-ultimate-html5-website-template-for-wineries-and-vineyards-fast-and-seo-optimized-461720.html?utm_campaign=grapeland_html&utm_source=website_travolgi&utm_medium=referral&aff=travolgi',
	},
	{
		img: require('../imgs/themes/buildxpert-html.webp'),
		name: 'BuildXpert - The Ultimate HTML5 Website Template for Architects and Builders',
		category: 'html5, architects, interior design, portfolio, builders',
		url: 'https://www.templatemonster.com/website-templates/buildxpert-the-ultimate-html5-website-template-for-architects-and-builders-441014.html?utm_campaign=buildxpert_html&utm_source=website_travolgi&utm_medium=referral&aff=travolgi'
	},
	{
		img: require('../imgs/themes/html-cyberwind.webp'),
		name: 'Cyberwind - Tailwind CSS Multipurpose App, Landing, IT Solutions and Business HTML5 Template',
		category: 'html5, tailwindcss, creative, psychologist, developers',
		url: 'https://www.templatemonster.com/landing-page-templates/cyberwind-tailwind-css-multipurpose-app-landing-it-solutions-and-business-website-html5-template-434639.html?utm_campaign=cyberwind_html&utm_source=website_travolgi&utm_medium=referral&aff=travolgi',
	},
	{
		img: require('../imgs/themes/html-leadup-landingpage.webp'),
		name: 'LeadUp Landing Page for Fashion Ecommerce Email Marketing and Leads Generation',
		category: 'html5, tailwindcss, landing page, ecommerce, fashion, shop',
		url: 'https://www.templatemonster.com/landing-page-templates/leadup-landing-page-template-for-fashion-ecommerce-email-marketing-generate-leads-and-increase-sale-417772.html?utm_campaign=leadup_landingpage_html&utm_source=website_travolgi&utm_medium=referral&aff=travolgi'
	},
	{
		img: require('../imgs/themes/flight-travel-html.webp'),
		name: 'Flight Travel - Airport Transfers and Airline Booking HTML5 Template',
		category: 'html5, sass, travel, flight, car transport, airline tickets',
		url: 'https://www.templatemonster.com/website-templates/flight-travel-airport-transfers-and-airline-booking-html5-website-template-398795.html?utm_campaign=ebookauthorpro_html&utm_source=website_travolgi&utm_medium=referral&aff=travolgi'
	},
	{
		img: require('../imgs/themes/techcorp-html.webp'),
		name: 'Tech Corp - IT Startup & Digital Business Services HTML5 Website Template',
		category: 'html5, business services, startup, it solution',
		url: 'https://www.templatemonster.com/website-templates/tech-corp-it-startup-and-digital-business-services-html5-website-template-383964.html?utm_campaign=ebookauthorpro_html&utm_source=website_travolgi&utm_medium=referral&aff=travolgi'
	},
	{
		img: require('../imgs/themes/ebookauthorpro-html.webp'),
		name: 'eBook Author Pro: Sell Your eBooks with the Author and Writer HTML5 Template',
		category: 'html5, ebooks, books, copywriter, education',
		url: 'https://www.templatemonster.com/website-templates/ebook-author-pro-sell-your-ebooks-with-the-author-and-writer-html5-website-template-378577.html?utm_campaign=ebookauthorpro_html&utm_source=website_travolgi&utm_medium=referral&aff=travolgi'
	},
	{
		img: require('../imgs/themes/techcorp-react.webp'),
		name: 'Tech Corp: Business Services & IT Solutions React js Website Template',
		category: 'react js, business, web services, hosting, crypto',
		url: 'https://www.templatemonster.com/website-templates/tech-corp-business-services-amp-it-solutions-react-js-website-template-377607.html?utm_campaign=techcorp_react&utm_source=website_travolgi&utm_medium=referral&aff=travolgi'
	},
	{
		img: require('../imgs/themes/synclove-bootstrap.webp'),
		name: 'Sync Love Landing Page Template: Elevate Your Dating Game with the Heart-Striking',
		category: 'html5, bootstrap, landing page, dating',
		url: 'https://www.templatemonster.com/landing-page-templates/sync-love-landing-page-template-elevate-your-dating-game-with-the-heart-striking-377591.html?utm_campaign=synclove_bootstrap&utm_source=website_travolgi&utm_medium=referral&aff=travolgi'
	},
	{
		img: require('../imgs/themes/ebookauthorpro-next.webp'),
		name: 'eBook Author Pro: Sell Your eBooks with the Author and Writer Next.js Template',
		category: 'next js, react, nodemailer, ebooks, writer',
		url: 'https://www.templatemonster.com/website-templates/ebookauthor-pro-sell-your-ebooks-with-the-author-and-writer-next-js-website-template-372666.html?utm_campaign=ebookauthorpro_next&utm_source=website_travolgi&utm_medium=referral&aff=travolgi'
	},
	{
		img: require('../imgs/themes/visionaryai_html.webp'),
		name: 'Visionary AI | Cutting-edge Dashboard UX for your Artificial Intelligence with Image Generator and Chatbot',
		category: 'html5, sass, dashboard, ai, image generator, chatbot',
		url: 'https://www.templatemonster.com/website-templates/visionary-ai-dashboard-website-template-for-artificial-intelligence-with-image-generator-amp-chat-346800.html?utm_campaign=jackpotzone_html&utm_source=website_travolgi&utm_medium=referral&aff=travolgi'
	},
	{
		img: require('../imgs/themes/jackpotzone-html.webp'),
		name: 'JackpotZone ♠ HTML5 Slot Machine & Casino Template',
		category: 'html5, casino, slot machine, games',
		url: 'https://www.templatemonster.com/website-templates/jackpotzone-html5-website-template-for-online-slot-machine-and-casino-websites-easy-to-customize-331801.html?utm_campaign=jackpotzone_html&utm_source=website_travolgi&utm_medium=referral&aff=travolgi'
	},
	{
		img: require('../imgs/themes/weathercast-html.webp'),
		name: 'WeatherCast HTML5 template with striking design',
		category: 'html5, weather, forecast, news',
		url: 'https://weathercast-html.travolgi.com'
	},
	{
		img: require('../imgs/themes/visit-react.webp'),
		name: 'Visit: the perfect Tour & Travel React Template',
		category: 'React js, travel, booking, weather',
		url: 'https://www.templatemonster.com/website-templates/visit-the-perfect-tour-amp-travel-booking-react-website-template-312399.html?utm_campaign=visit_react&utm_source=website_travolgi&utm_medium=referral&aff=travolgi'
	},
	{
		img: require('../imgs/themes/sled-html.webp'),
		name: 'Sled Html5 Gym and Multipurpose Template',
		category: 'html5, Gym, Restaurant, Bar, Halloween',
		url: 'https://www.templatemonster.com/website-templates/sled-html5-for-halloween-gym-restaurant-bar-and-multipurpose-website-template-308857.html?utm_campaign=sled_html&utm_source=website_travolgi&utm_medium=referral&aff=travolgi'
	},
	{
		img: require('../imgs/themes/astro-html.webp'),
		name: 'Astro Html Crypto and Multipurpose Template',
		category: 'html5, sass, NFT, crypto',
		url: 'https://www.templatemonster.com/website-templates/astro-html-crypto-and-multipurpose-website-template-299220.html?utm_campaign=astro_html&utm_source=website_travolgi&utm_medium=referral&aff=travolgi'
	},
	{
		img: require('../imgs/themes/sled-react.webp'),
		name: 'Sled React Multipurpose Template',
		category: 'React js, Halloween, Gym, Restaurant, Bar',
		url: 'https://www.templatemonster.com/website-templates/sled-react-website-template-for-gym-restaurant-bar-and-multipurpose-template-252058.html?utm_campaign=sled_react&utm_source=website_travolgi&utm_medium=referral&aff=travolgi'
	},
	{
		img:  require('../imgs/themes/sled-xd.webp'),
		name: 'Sled UI Kit of Adobe XD',
		category: 'UI Templates, Gyms, Beer, Restaurants',
		url: 'https://www.templatemonster.com/ui-elements/sled-gyms-beer-restaurants-ui-kit-for-adobe-xd-251261.html?utm_campaign=sled_xd&utm_source=website_travolgi&utm_medium=referral&aff=travolgi'
	}
]
